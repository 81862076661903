import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { User } from '@yukawa/chain-main-angular-core';
import {
  IAuthTokenResponse,
  IAuthResponse,
  AuthToken,
  AuthRequestEndpoints,
  SessionStoreService,
} from '@yukawa/chain-main-angular-session';
import { UserService } from './user/user.service';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import * as REALM from '../types/realm.types';
import { CanActivateFn } from '@angular/router';
import { UserInfo } from 'app/types/domain/profile';
import { ChemUser } from 'app/types/profile.types';

const SECURITY_URL = 'securityUrl';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;
  private _httpClient = inject(HttpClient);
  private _userService = inject(UserService);
  private _configService = inject(ConfigService);
  private _sessionStore = inject(SessionStoreService);
  private _token: AuthToken|null = null;

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------



  get sessionToken(): AuthToken|null {
    
    if(this._sessionStore.get('token') == 'undefined'){
      this._sessionStore.remove('token');
      return null;
    }
    return this._sessionStore.getJSON('token') as AuthToken;
  }

  /**
   * Setter & getter for access token
   */
  set sessionToken(token: AuthToken) {
    this._sessionStore.setJSON('token', token);
  }

  /**
   * Forgot password
   *
   * @param username
   */
  forgotPassword(username: string): Observable<any> {
    return this._httpClient.post(
      this._configService.formatUrl('userUrl') + '/password/self-reset',
      { username: username }
    );
  }

  resetPassword(password: string, token:string): Observable<any> {
    return this._httpClient.post(this._configService.formatUrl('userUrl')+'/password/self-reset-confirm', {
      password: password
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },  
    });
  }

  signIn(credentials: {
    email: string;
    password: string;
    rememberMe?: boolean;
  }): Observable<IAuthTokenResponse> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post(
        this._configService.formatUrl(SECURITY_URL) +
          AuthRequestEndpoints.createToken,
        credentials
      )
      .pipe(
        switchMap((response: any) => {
          this._token = new AuthToken(response);
          // Store the access token in the local storage
          this.sessionToken = this._token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService
            .loadUser(this._token)
            .subscribe((response: ChemUser) => {
              this._sessionStore.set('user', JSON.stringify(response));
            });

          // Return a new observable with the response
          return of(response);
        })
      );
  }


  signInUsingToken(): Observable<boolean> {
    // Sign in using the token
    return this._httpClient
      .get<IAuthResponse>(
        this._configService.formatUrl(SECURITY_URL) +
          AuthRequestEndpoints.refreshToken,
        {
          headers: {
            Authorization: `Bearer ${this.sessionToken?.refresh_token}`,
          },
        }
      )
      .pipe(
        switchMap((response:IAuthResponse) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.

          if (response.access_token) {
            this._token = new AuthToken({
              ...this.sessionToken,
              ...response,
            } as IAuthTokenResponse);
          }else{
            return of(false); 
          }
          this.sessionToken = this._token;
          // Set the authenticated flag to true
          this._authenticated = true;
          // Store the user on the user service
          this._userService
            .loadUser(this._token)
            .subscribe((response: ChemUser) => {
              this._sessionStore.set('user', JSON.stringify(response));
            });
          // Return true
          return of(true);
        }),
        catchError((error) => {
          // Catch "401 Unauthorized" responses
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // Sign out
            this.signOut();
    
            // Reload the app
            location.reload();
          }
    
          return throwError(error);
        })
      );
  }


  signOut(): Observable<unknown> {
    console.debug('AuthService: signing out');
    // ivalidate Token on the server
    this._httpClient
    .delete(this._configService.formatUrl(SECURITY_URL) + AuthRequestEndpoints.revokeToken, {
      headers: {
        Authorization: `Bearer ${this.sessionToken?.refresh_token}`,
      },
    
    })
    .subscribe((res)=>{
      console.debug('AuthService: Token revoked', res);
    });
    // Remove the access token from the local storage
    this._sessionStore.remove('token');
    this._sessionStore.remove('user');
    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    //console.debug('AuthSevice: checking authentication status', this._authenticated, !this.sessionToken, this.sessionToken?.accessToken);
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.sessionToken) {
      return of(false);
    }

    // Check the access token expire date
    if (this.sessionToken.accessToken) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  hasRole(role: string): boolean{
    return (this.sessionToken?.scope.includes(role) || false) || (this.sessionToken?.scope.includes('ROLE_ADMIN') || false) 
  }
  
  anyRole(roles:string[]):boolean{
    return roles.some((role) => this.hasRole(role));
  }

  canCreateTxSource():boolean{
    return this.hasRole(REALM.ROLE_ALL_SRC_E);
  }

  canCreateCompanyUser():boolean{
    return this.hasRole(REALM.ROLE_COMP_USR_E);
  }

  canCreateCompany():boolean{
    return this.hasRole(REALM.ROLE_ALL_COMP_E);
  }
  isAdmin():boolean{
    return this.sessionToken?.scope.includes("ROLE_ADMIN") || false;
  }
  isSupport():boolean{
    return this.sessionToken?.scope.includes("ROLE_ALL_SRC_E") || false;
  }

  canDownload():boolean {
    return true;
    
}
  canUploadForCompany ():boolean {
    return true;
}

}