import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { AuthToken } from '@yukawa/chain-main-angular-session/model/auth';
import {
  SessionStoreService,
  User,
  UserInfo
} from '@yukawa/chain-main-angular-session';
import { BehaviorSubject, catchError, forkJoin, lastValueFrom, map, Observable, of, ReplaySubject, Subscription, tap } from 'rxjs';
import { Group, UserFilter } from '@yukawa/chain-main-angular-core';
import { Company, Invitation, UserInfo as Profile } from 'app/types/domain/profile';
import { ChemUser } from 'app/types/profile.types';
import { QueryResult } from '@yukawa/chain-base-angular-domain';

export enum UserRestEndpoints {
  createUser = '/',
  updateUser = '/',
  loadUser = '/',
  deleteUser = '/',
  synchronizeRoles = '/admin/synchronizeRoles',
  exportUsers = '/export',
  merge = '/merge',
  changePassword = '/password/change',
  resetPassword = '/password/reset',
  queryUsers = '/query',
  registerUser = '/register',
}

@Injectable({ providedIn: 'root' })
export class UserService {
  
  private _httpClient = inject(HttpClient);
  private _configService = inject(ConfigService);
  private _sessionStore = inject(SessionStoreService);

  private _user: ReplaySubject<ChemUser> = new ReplaySubject<ChemUser>(1);
  userList: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  userListRows: BehaviorSubject<number> = new BehaviorSubject<number>(0);


  set user(value: ChemUser) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<ChemUser> {
    return this._user.asObservable();
  }

  get userList$(): Observable<User[]> {
    return this.userList.asObservable();
  }

  public loadUser(token: AuthToken): Observable<ChemUser> {
    if (this._sessionStore.getJSON('user')) {
      this._user.next(this._sessionStore.getJSON('user') as ChemUser);
      return this._user.asObservable();
    }
    return this._httpClient.get<UserInfo>(this._configService.formatUrl('userUrl') +`${UserRestEndpoints.loadUser}${token.username}`)
      .pipe(
        tap((user: ChemUser) => {
          console.debug('Userservice: received User:', user);
          this._user.next(user);
        })
      );
  }

  update(user: Profile): Observable<any> {
    return this._httpClient.post<ChemUser>(this._configService.formatUrl('userProfileUrl')+"/merge", {
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName, 
    });
  }

  
  queryUsers(userFilter: UserFilter): Subscription {
    userFilter.withGroupContexts = true;
    return this._httpClient
      .post<QueryResult<User>>(this._configService.formatUrl('userProfileUrl') + `${UserRestEndpoints.queryUsers}`, userFilter)
      .pipe().subscribe(
        (response: QueryResult<User>) => {
          console.log("UserService: query Response",response);
          this.userList.next(response.items);
          this.userListRows.next(response.rows);
        }
      );
  }
  getUserCompanyId(): number|undefined {
    return (this._sessionStore.getJSON('user') as ChemUser).details?.['comp'] as number;
  }

  loadProfile(userId?:string): Observable<Profile> {
    if(userId === undefined && this._sessionStore.getJSON('user')){
      userId = (this._sessionStore.getJSON('user') as ChemUser)?.userId;
      if(userId === undefined){
        return of({});
      }
    }
    return this._httpClient.get<Profile>(this._configService.formatUrl('userProfileUrl')+'/', {
      params: { key: userId! } 
    })
  }

  deleteUser(userId: UserInfo): Observable<any> {
    return this._httpClient.delete(this._configService.formatUrl('userUrl') + `/${userId.username}`);
  }

  getAssignableGroups(profile: Profile): Observable<Group[]> {
    return this._httpClient.get<Group[]>(this._configService.formatUrl('userProfileUrl') + '/group/find-assignable', {
      params: { userId: profile.userId! }
    });
  }
  addGroup(profile: Profile, group: Group): Observable<any> {
    return this._httpClient.post(this._configService.formatUrl('userProfileUrl') + '/group/assign', {},{
      params: { userId: profile.userId!, groupName: group.name! }
    })
  }
  removeGroup(profile: Profile, group: Group): Observable<any> {
    return this._httpClient.delete(this._configService.formatUrl('userProfileUrl') + '/group/remove',{
      params: { userId: profile.userId!, groupName: group.name! }
    })
  }
}

// https://chem.dev.cloud.yukawa.de/web/invitation?token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhbGV4bWVyc2RvcmZAZ21haWwuY29tIiwiaWF0IjoxNzI5MTc2MjY3LCJleHAiOjE3MzAzODU4NjcsInNjb3BlIjpbIlJPTEVfSU5WSVRFX0NPTkYiXSwiZGV0YWlscyI6eyJpbnZpdGF0aW9uSWQiOjEwNH19.EJW1Hkhoh5SD8yTrKdAoSY1_RDyuterIIksChXTlDmU
